import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Trusted Cloud Partner</title>
      </Helmet>
      <Navbar logoSrc="./assets/cloud%20desk%20logo-1500h.jpg"></Navbar>
      <Hero action1="Learn More" heading1="Your Trusted Cloud Partner"></Hero>
      <Features1></Features1>
      <CTA></CTA>
      <Features2></Features2>
      <Steps></Steps>
      <Testimonial></Testimonial>
      <Contact></Contact>
      <Footer content3="© 2024 Cloud Desk Technology. All rights reserved."></Footer>
    </div>
  )
}

export default Home
